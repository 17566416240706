import React, { useCallback, useEffect, useState, useRef } from 'react';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ExifReader from 'exifreader';
import {
  Excalidraw,
  useHandleLibrary,
  MainMenu,
  MIME_TYPES,
  exportToBlob,
  restoreElements,
  loadSceneOrLibraryFromBlob,
  loadFromBlob,
} from "@excalidraw/excalidraw";
import SaveIcon from './save-disk.png';
import FlowChart from './libraryData/flow-chart-symbols.excalidrawlib';
import AzureChart from './libraryData/azure-compute.excalidrawlib';
import AzureContainerChart from './libraryData/azure-containers.excalidrawlib';
import AzureGeneralChart from './libraryData/azure-general.excalidrawlib';
import AzureNetworkChart from './libraryData/azure-network.excalidrawlib';
import AzureStorageChart from './libraryData/azure-storage.excalidrawlib';
import TtIconsChart from './libraryData/it-icons.excalidrawlib';
import ThreedcoordinateChart from './libraryData/3d-coordinate-systems-graphs.excalidrawlib';
import ThreedShapesChart from './libraryData/3d-shapes.excalidrawlib';
import AdjustableChart from './libraryData/an-adjustable-arrow-shape-that-can-be-modified-in-a-number-of-ways-to-suit-different-use-cases.excalidrawlib';
import BiologyChart from './libraryData/biology.excalidrawlib';
import BoardgameChart from './libraryData/boardgame.excalidrawlib';
import BpmnChart from './libraryData/bpmn.excalidrawlib';
import ChartsChart from './libraryData/charts.excalidrawlib';
import ChessChart from './libraryData/chess-set.excalidrawlib';
import CoordinatesChart from './libraryData/coordinates.excalidrawlib';
import DecisionChart from './libraryData/decision-flow-control.excalidrawlib';
import DsvisualizationsChart from './libraryData/ds-visualizations.excalidrawlib';
import ElectricalChart from './libraryData/electrical-engineering.excalidrawlib';
import FlowchartChart from './libraryData/flow-chart-symbols.excalidrawlib';
import GraphsChart from './libraryData/graphs.excalidrawlib';
import MacroeconomicsChart from './libraryData/macroeconomics.excalidrawlib';
import MathteacherChart from './libraryData/math-teacher-library.excalidrawlib';
import MathematicalChart from './libraryData/mathematical-symbols.excalidrawlib';
import MedicineChart from './libraryData/medicine.excalidrawlib';
import OrganicChart from './libraryData/organic-chemistry-basics.excalidrawlib';
import PeriodicChart from './libraryData/periodic-table.excalidrawlib';
import PlayingChart from './libraryData/playing-cards.excalidrawlib';
import PolygonsChart from './libraryData/polygons.excalidrawlib';
import ScienceChart from './libraryData/science-chemistry-gcse.excalidrawlib';
import TtrpgChart from './libraryData/ttrpg-clocks.excalidrawlib';
import UMLERChart from './libraryData/UML-ER-library.excalidrawlib';
import UMLlibraryChart from './libraryData/uml-library-activity-diagram.excalidrawlib';
import { BallTriangle } from 'react-loader-spinner'



const libraryUrls = [
  FlowChart,
  AzureChart,
  AzureContainerChart,
  AzureGeneralChart,
  AzureNetworkChart,
  AzureStorageChart,
  TtIconsChart,
  ThreedcoordinateChart,
  ThreedShapesChart,
  AdjustableChart,
  BiologyChart,
  BoardgameChart,
  BpmnChart,
  ChartsChart,
  ChessChart,
  CoordinatesChart,
  DecisionChart,
  DsvisualizationsChart,
  ElectricalChart,
  FlowchartChart,
  GraphsChart,
  MacroeconomicsChart,
  MathteacherChart,
  MathematicalChart,
  MedicineChart,
  OrganicChart,
  PeriodicChart,
  PlayingChart,
  PolygonsChart,
  ScienceChart,
  TtrpgChart,
  UMLERChart,
  UMLlibraryChart,
];


function App() {
  const [excalidrawInstance, setExcalidrawInstance] = useState(null);
  const [isCollaborating, setIsCollaborating] = useState(false);
  const [loading, setLoading] = useState(false);

  useHandleLibrary({ excalidrawInstance });
  const excalidrawRef = useRef(null);

  useEffect(() => {
    if (!excalidrawInstance) {
      return;
    }
  }, [])

  useEffect(() => {
    fetchLibraries();
  }, []);

  async function urlToBlob(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.blob();
  }
  const loadSceneFromBlob = async (blob, api) => {
    try {
      const contents = await loadSceneOrLibraryFromBlob(blob, null, null);
      console.log("contents>>>", contents)
      if (contents.type === MIME_TYPES.excalidraw) {
        api.updateScene(contents.data);
      } else {
        console.error("Blob does not contain valid scene data.");
      }
    } catch (error) {
      console.error("Error loading scene from blob:", error);
    }
  };




  const fetchData = async (api, imgObj) => {
    const res = await fetch(imgObj);
    const imageData = await res.blob();
    const reader = new FileReader();
    reader.readAsDataURL(imageData);

    reader.onload = function () {
      const imagesArray = [
        {
          id: "rocket",
          dataURL: reader.result,
          mimeType: MIME_TYPES.png,
          created: 1644915140367,
          lastRetrieved: 1644915140367
        }
      ];

      //@ts-ignore
      api.addFiles(imagesArray);
    };
  };


  const updateScene = (api) => {

    const sceneData = {
      elements: restoreElements(
        [

          {
            fileId: "rocket",
            type: "image",
            x: 100,
            y: 100,
            width: 300,
            height: 300,
            angle: 0,
            strokeColor: "transparent",
            backgroundColor: "transparent",
            fillStyle: "hachure",
            strokeWidth: 1,
            strokeStyle: "solid",
            roughness: 1,
            opacity: 100,
            groupIds: [],
            strokeSharpness: "round",
            seed: 707269846,
            version: 143,
            versionNonce: 2028982666,
            isDeleted: false,
            boundElements: null,
            updated: 1644914782403,
            link: null,
            status: "pending",
            scale: [1, 1],
            exportEmbedScene: true,
          }
        ],
        null
      ),
      appState: {
        viewBackgroundColor: "#edf2ff",
        isBindingEnabled: true

      }
    };
    api.updateScene(sceneData);
  };



  const loadEmbeddedSceneFromImage = async (imageUrl, api) => {
    try {
      console.log("imageUrl", imageUrl)
      // const response = await fetch(imageUrl);
      // const blob = await response.blob();
      // console.log("blob", blob)
      // var file = new File([blob], 'demo.png', null);
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      // const loadImageBlob  = await loadSceneFromBlob(blob)
      console.log("blob", blob)
      const file = new File([imageUrl], 'image.png');
      const contents = await (blob, null, null)
      api.importImage(file)
      // const contents = await loadSceneOrLibraryFromBlob(blob, null, null)
      console.log("contents>>>>", contents)
      // const arrayBuffer = await blob.arrayBuffer();
      // console.log("arrayBuffer", arrayBuffer)
      // const tags = ExifReader.load(arrayBuffer);
      // console.log("tags>>>", tags)
      // if (tags && tags.UserComment) {
      //   const sceneData = JSON.parse(tags.UserComment.description);
      //   console.log("sceneData,,>>>", sceneData)
      //   api.updateScene({
      //     elements: sceneData.elements,
      //     appState: {},
      //     commitToHistory: true,
      //   });
      // } else {
      //   console.error('No embedded scene data found in the image metadata.');
      // }
    }
    catch (error) {
      console.error('Error loading embedded scene:', error);
    }
  }
  const extractEmbeddedScene = async (imageBitmap) => {
    console.log("imageBitmap>>>", imageBitmap)
    return {
      "elements": [
        {
          "id": "example-1",
          "type": "rectangle",
          "x": 100,
          "y": 100,
          "width": 200,
          "height": 100,
          "angle": 0,
          "strokeColor": "#000000",
          "backgroundColor": "#ff0000",
          "fillStyle": "hachure",
          "strokeWidth": 1,
          "roughness": 1,
          "opacity": 100,
          "groupIds": [

          ],
          "version": 1,
          "versionNonce": 1,
          "isDeleted": false
        }
      ]
    }
  }

  const handleEditImage = async (api) => {

    // const urlObj = new URL('https://canvas.prepami.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzI5NjcwNzEzLCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.V6_uDQh5QNZTYyeLcNXthK0iF4Rr79yhZ2PUWBoh4Xk&editImage=https://object-storage-1.prepami.com/adminbucket/ms-qbank/admingmailcom/e3096b95-f2d8-402b-be85-2b21d87dd259.png');
    const urlObj = new URL(window.location.href);


    // Get the editImage parameter
    const editImageUrl = urlObj.searchParams.get('editImage');
    // const editImageUrl = 'https://object-storage-1.prepami.com/adminbucket/ms-qbank/admingmailcom/5f6d0907-4d9c-4841-99fe-1369d257a631.png';
    fetchLibraries();

    await fetchData(api, editImageUrl)

    if (editImageUrl) {
      // await loadEmbeddedSceneFromImage(editImageUrl, api)
      await updateScene(api);
      // const imageUrl = 'https://object-storage-1.prepami.com/adminbucket/ms-qbank/admingmailcom/5f6d0907-4d9c-4841-99fe-1369d257a631.png';
      // urlToBlob(imageUrl).then(blob => loadSceneFromBlob(blob, api));
    }
  }

  const [pointerData, setPointerData] = useState(null);
  // Function to save
  const handleSave = useCallback(async () => {
    if (excalidrawInstance) {
      setLoading(true);
      const blob = await exportToBlob({
        elements: excalidrawInstance?.getSceneElements(),
        mimeType: "image/png",
        appState: { exportEmbedScene: true },
        files: excalidrawInstance?.getFiles()
      });
      console.log("blob>>>", blob)
      let date = new Date().getTime()
      let fileName = `excalidraw-drawing_name_${date}.png`
      var file = new File([blob], fileName, { lastModified: date });
      let formData = new FormData();
      formData.append('file', file);
      const urlObj = new URL('https://canvas.prepami.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzI5NjcwNzEzLCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.V6_uDQh5QNZTYyeLcNXthK0iF4Rr79yhZ2PUWBoh4Xk&editImage=https://object-storage-1.prepami.com/adminbucket/ms-qbank/admingmailcom/e3096b95-f2d8-402b-be85-2b21d87dd259.png');

      // const urlObj = new URL(window.location.href);
      const storedToken = urlObj.searchParams.get('token');
      console.log("storedToken>>>>", storedToken)

      axios.post(`https://storage.prepami.com/api/FileUploader/UploadFile2?OriginMS=qbank`, formData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(async response => {
          console.log("Hiii responseDatat", response?.data)
          sendMessageToParent(response?.data)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 1500)
          console.log(error)
        }
        )

      const elements = excalidrawInstance.getSceneElements();
      console.log("elements", elements)
      if (elements && elements.length > 0) {
        // setLoading(false)
        toast.success("Scene saved successfully!");
      } else {
        // setLoading(false)
        toast.error("No scene elements to save.");
      }
    }
  }, [excalidrawInstance]);

  function sendMessageToParent(data) {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
    // const data = 'This is the string data from the iframe!';
    // Send message to parent window
    window.parent.postMessage(data, '*'); // Use '*' for simplicity, or restrict it with the parent's domain
  }

  const fetchLibraries = async (api) => {
    try {
      const libraries = await Promise.all(
        libraryUrls.map((url) => fetch(url).then((res) => res.json()))
      );
      const combinedLibrary = libraries.flatMap((lib) => lib.libraryItems || lib.library || []);

      await api?.updateLibrary({ libraryItems: new Set(combinedLibrary) });
    } catch (error) {
      console.error("Failed to load libraries:", error);
    }
  };


  const handleLibrary = () => {
  }

  // Function to onChange
  const handleOnChange = (elements) => {

    excalidrawInstance?.updateScene({ elements: elements });
  };

  // Function to reset
  const handleReset = () => {
    excalidrawInstance.resetScene()
  };
  const renderMenu = () => {
    return (
      <MainMenu>
        <MainMenu.DefaultItems.LoadScene />
        <MainMenu.DefaultItems.SaveAsImage />
        <MainMenu.DefaultItems.Export />
      </MainMenu>
    );
  };
  return (
    <div className="App">

      <>
        {loading && (
          <div className='loaderStyle'>
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loading}
            />
          </div>
        )}
        <div className="App-button-row">
          <button className='App-button' onClick={handleSave}>
            <img src={SaveIcon} alt='saveIcon' style={{ marginRight: '5px', width: 24, height: 24 }} />
            Save and Close
          </button>
        </div>
        <div style={{ height: '91vh' }}>

          <Excalidraw
            excalidrawAPI={(api) => {
              setExcalidrawInstance(api)
              fetchLibraries(api);
              handleEditImage(api)
            }
            }

            onChange={(elements, state) => {
              // handleOnChange(elements)
              // console.log("elements", elements)
            }}
            onPointerUpdate={(payload) => {
              handleLibrary()
              setPointerData(payload)
            }}
            name="Testing Freekey.ai"
          >
            {renderMenu()}
          </Excalidraw>
        </div>
      </>
      <ToastContainer /> {/* Add ToastContainer to display toasts */}
    </div>
  );
}

export default App;
